// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autos-js": () => import("./../../../src/pages/autos.js" /* webpackChunkName: "component---src-pages-autos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-addcar-js": () => import("./../../../src/pages/dashboard/addcar.js" /* webpackChunkName: "component---src-pages-dashboard-addcar-js" */),
  "component---src-pages-dashboard-carfile-js": () => import("./../../../src/pages/dashboard/carfile.js" /* webpackChunkName: "component---src-pages-dashboard-carfile-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-exportation-js": () => import("./../../../src/pages/exportation.js" /* webpackChunkName: "component---src-pages-exportation-js" */),
  "component---src-pages-fichevehicule-js": () => import("./../../../src/pages/fichevehicule.js" /* webpackChunkName: "component---src-pages-fichevehicule-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pieces-js": () => import("./../../../src/pages/pieces.js" /* webpackChunkName: "component---src-pages-pieces-js" */)
}

